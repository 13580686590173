import React, { useState } from 'react'
import { Button, Typography, message, Switch, Input } from 'antd'
import api from '../../api'
import useNotification from '../../hooks/useNotification'
import moment from 'moment'

const ItemText = ({ changeFunc, label, value }) => {
  return (
    <span style={{ display: 'flex', justifyContent: 'space-between', paddingTop: 10 }}>
      <Typography.Text strong style={{ flex: 1, paddingRight: 10 }}>
        {label}{' '}
      </Typography.Text>
      <Typography.Text style={{ flex: 2 }}>{value}</Typography.Text>
    </span>
  )
}
export default function Request({ data, dispatchReload }) {
  const [ROLE] = React.useState(localStorage.getItem('role'))
  const { pushNotifications } = useNotification()
  const [currentReason, setCurrentReason] = useState('')
  const [canWithdraw, setCanWithdraw] = useState(false)

  const request = data ? data.filter(item => item.status === 'pending')[0] : null
  async function updateContract() {
    await api.put(`/contracts/${request.contractId}`, {
      response: 'pending',
    })
  }

  async function sumbitRequest() {
    if (!canWithdraw && !currentReason) {
      message.error('Bạn chưa nhập lý do từ chối')
      return
    }

    await api.put(`/requests/${request._id}`, { status: canWithdraw ? 'accepted' : 'rejected', error: currentReason })

    await updateContract()
    if (canWithdraw) {
      pushNotifications({
        to: request.userId,
        message: 'Yêu cầu rút tiền được duyệt',
        description: 'Hệ thống đã xác nhận yêu cầu rút tiền của bạn. Rút tiền thành công.',
      })
    } else {
      pushNotifications({
        to: request.userId,
        message: 'Yêu cầu rút tiền bị từ chối',
        description: 'Yêu cầu rút tiền của bạn đã bị từ chối. Chi tiết trong lịch sử rút tiền.',
      })
    }
    
    message.success('Cập nhật thành công')

    dispatchReload()
  }
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        background: '#fff',
        padding: 20,
        borderRadius: 10,
        minWidth: 420,
        flex: 2,
        margin: '0px 10px',
        maxHeight: 400,
      }}
    >
      {request ? (
        <>
          <Typography.Title level={5} style={{ textAlign: 'center' }}>
            Yêu cầu rút tiền
          </Typography.Title>
          <hr />
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              minHeight: '50%',
            }}
          >
            <ItemText
              label="Số tiền yêu cầu"
              value={request?.amount ? request?.amount.toLocaleString() : 0}
            />
            <ItemText
              label="Ngày tạo"
              value={request?.created_at ? moment(request.created_at).format('DD/MM/YYYY HH:mm:ss') : ' '}
            />
            <span
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '10px 0',
              }}
            >
              <Typography.Text strong style={{ flex: 1, paddingRight: 10, fontSize: 14 }}>
                Rút tiền :
              </Typography.Text>
              <span style={{ flex: 2 }}>
                <Switch
                  value={canWithdraw}
                  onChange={() => setCanWithdraw(prev => !prev)}
                />
              </span>
            </span>

            {
              !canWithdraw &&
              <Input
                onChange={e => setCurrentReason(e.target.value)}
                value={currentReason}
                placeholder='Nhập lý do từ chối'
              />
            }

            {(ROLE == 'ROOT' || ROLE == 'ADMIN') && (
              <div style={{ justifyContent: 'center', display: 'flex', margin: '10px 0'}}>
                <Button type={canWithdraw ? "primary" : "default"} onClick={sumbitRequest}>
                  {
                    canWithdraw ? 'Duyệt' : 'Từ chối'
                  }
                </Button>
              </div>
            )}
          </div>
        </>
      ) : (
        <Typography.Text strong style={{ textAlign: 'center' }}>
          Hiện không có yêu cầu
        </Typography.Text>
      )}
    </div>
  )
}
