/* eslint-disable import/no-anonymous-default-export */
import React, { useEffect, useState } from 'react'
import api from '../../api'
import { Table, Typography, Space, Modal, Button, Form, Input, message, Popconfirm } from 'antd'
import moment from 'moment'
import { HighlightOutlined } from '@ant-design/icons'
export default function CSKH() {
  const [form] = Form.useForm();
  const [onReload, setOnReload] = useState(false)
  const [refetcher, setRefetcher] = useState(false)
  const [dataCSKh, setDataCSKh] = useState({})

  useEffect(() => {
    ;(async () => {
      const { data } = await api.get('/cskh/all')
      console.log("data", data.data[0]);
      setDataCSKh(data.data[0]);
      form.setFieldsValue({ 
        zalo: data.data[0].zalo,
        telegram: data.data[0].telegram,
        hotline: data.data[0].hotline,
      });
    })()
  }, [onReload, refetcher])
  const [text, setText] = useState('')

  const onFinish = async (e) => {
    try {
      console.log('zoiiii', e);
      const dataBody = {
        zalo: e.zalo,
        telegram: e.telegram,
        hotline: e.hotline,
      }
      await api.put(`/cskh/${dataCSKh._id}`, dataBody)
      message.success('Cập nhật thành công')
      setRefetcher((prev) => !prev)
    } catch (err) {
      message.error(err);
    }
  };

  async function update() {
    try {
      await api.put("/company", {
        phone: text
      });
      setText("");
      message.success("Thêm thành công.");
      setRefetcher((prev) => !prev);
      // setAddSupporter(false);
    } catch (err) {
      message.error("Có lỗi xảy ra. Vui lòng thử lại sau.");
    }
  }
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Form
          name="wrap"
          labelCol={{
            flex: "120px",
          }}
          labelAlign="left"
          labelWrap
          wrapperCol={{
            flex: 0,
          }}
          colon={false}
          style={{
            maxWidth: 1000,
          }}
          form={form}
          onFinish={onFinish}
        >
          <Form.Item
            label="Zalo"
            name="zalo"
            rules={[
              {
                required: true,
                message: "Vui lòng nhập zalo!",
              },
            ]}
          >
            <Input placeholder="Nhập zalo" className="input-cskh" />
          </Form.Item>

          <Form.Item
            label="Telegram"
            name="telegram"
            rules={[
              {
                required: true,
                message: "Vui lòng nhập telegram!",
              },
            ]}
          >
            <Input placeholder="Nhập telegram" className="input-cskh" />
          </Form.Item>

          <Form.Item
            label="Hotline"
            name="hotline"
            rules={[
              {
                required: true,
                message: "Vui lòng nhập Hotline!",
              },
            ]}
          >
            <Input placeholder="Nhập Hotline" className="input-cskh" />
          </Form.Item>

          <Form.Item label=" ">
            <Button type="primary" htmlType="submit">
              Cập nhật
            </Button>
          </Form.Item>
        </Form>
      </div>
    </>
  );
}
