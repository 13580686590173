import React, { useState, useEffect } from 'react'
import { Typography, Switch, Select, Button, Modal, message, Input, Space } from 'antd'
import { HighlightOutlined } from '@ant-design/icons'
import Template from './Template'
import api from '../../api'
import useNotifications from '../../hooks/useNotification'
export default function Contracts({ data, profile, dispatchReload }) {
  const { pushNotifications } = useNotifications()
  const [ROLE] = React.useState(localStorage.getItem('role'))
  const [visible, setVisible] = useState(false)
  const [currentReason, setCurrentReason] = useState(data[0]?.response || {})
  const [canWithdraw, setCanWithdraw] = useState(data[0]?.response !== 'pending')
  const [loading, setLoading] = useState(false)
  async function confirmContract() {
    setLoading(true)
    await api.put(`/contracts/${data[0]._id}/confirm`, {
      status: 'accepted',
    })
    pushNotifications({
      to: profile._id,
      message: 'Hợp đồng vay đã được duyệt',
      description: `Khoản vay ${data[0].amount.toLocaleString()} VND, ${
        data[0].times
      } tháng đã được thông qua. Hãy yêu cầu rút tiền.`,
    })
    message.success('Cập nhật thành công.')
    setLoading(false)
    dispatchReload()
  }

  async function updateContract() {
    await api.put(`/contracts/${data[0]._id}?setHidden=true`, {
      response: canWithdraw ? "accepted" : "pending",
    });

    message.success('Cập nhật thành công.')
    // setEditReason(false)
    dispatchReload()
  }

  async function updateItem(field, value) {
    let obj = {}
    obj[field] = value
    await api.put(`/contracts/${data[0]._id}`, { ...obj })
    message.success('Cập nhật thành công.')
    dispatchReload()
    setEditMonth(false)
  }
  const [editMonth, setEditMonth] = useState(false)

  const [currentTimes, setCurrentTimes] = useState(data[0]?.times)

  useEffect(() => {
    setCanWithdraw(data[0]?.response !== 'pending')
  }, [data[0]?.response])

  return (
    <>
      {data[0] && (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
            background: "#fff",
            padding: 20,
            borderRadius: 10,
            minWidth: 420,
            flex: 1,
            maxHeight: 400,
          }}
        >
          <ItemText
            label="Mã hợp đồng"
            value={data[0].slug}
            editable
            changeFunc={(e) => updateItem("slug", e)}
          />
          <ItemText
            label="Số tiền "
            value={data[0].amount.toLocaleString()}
            editable
            changeFunc={(e) =>
              updateItem("amount", parseInt(e.split(",").join("")))
            }
          />
          <span
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: 5,
            }}
          >
            <Typography.Text
              strong
              style={{ flex: 1, paddingRight: 10, fontSize: 16 }}
            >
              Trạng thái
            </Typography.Text>
            {data[0].status === "pending" ? (
              <div style={{ flex: 2 }}>
                <Button
                  type="primary"
                  size={"small"}
                  onClick={confirmContract}
                  loading={loading}
                >
                  Duyệt
                </Button>
              </div>
            ) : (
              <div style={{ flex: 2 }}>Đã duyệt</div>
            )}
          </span>

          <span
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: 5,
            }}
          >
            <Typography.Text
              strong
              style={{ flex: 1, paddingRight: 10, fontSize: 16 }}
            >
              Thời hạn :
            </Typography.Text>
            {editMonth ? (
              <div style={{ flex: 2 }}>
                <Select
                  style={{ minWidth: 100 }}
                  value={currentTimes}
                  onChange={(e) => setCurrentTimes(e)}
                >
                  <Select.Option value={6}>6 tháng</Select.Option>
                  <Select.Option value={12}>12 tháng</Select.Option>
                  <Select.Option value={24}>24 tháng</Select.Option>
                  <Select.Option value={36}>36 tháng</Select.Option>
                  <Select.Option value={48}>48 tháng</Select.Option>
                </Select>
                <Button
                  type="primary"
                  onClick={() => updateItem("times", currentTimes)}
                >
                  Xác nhận
                </Button>
                <Button onClick={() => setEditMonth(false)}>Huỷ</Button>
              </div>
            ) : (
              <Typography.Text
                style={{
                  fontSize: 16,
                  flex: 2,
                }}
              >
                {`${data[0].times} tháng `}
                <HighlightOutlined
                  style={{ color: "#4996f7" }}
                  onClick={() => setEditMonth(true)}
                />
              </Typography.Text>
            )}
          </span>
          <ItemText
            label="Mã OTP"
            value={data[0].otp}
            editable
            changeFunc={(e) => updateItem("otp", e)}
          />
          {(ROLE == "ROOT" || ROLE == "ADMIN") &&
            data[0].status == "accepted" && (
              <>
                <span
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: 5,
                  }}
                >
                  <Typography.Text
                    strong
                    style={{ flex: 1, paddingRight: 10, fontSize: 16 }}
                  >
                    Rút tiền :
                  </Typography.Text>
                  <span style={{ flex: 2 }}>
                    <Switch
                      checked={canWithdraw}
                      onChange={() => setCanWithdraw((prev) => !prev)}
                    />
                  </span>
                </span>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    paddingTop: 10,
                  }}
                >
                  <Button type="primary" onClick={() => setVisible(true)}>
                    Xem hợp đồng
                  </Button>
                  {data[0].status == "accepted" && (
                    <Button type="primary" onClick={updateContract}>
                      Cập nhật
                    </Button>
                  )}
                </div>
              </>
            )}
        </div>
      )}
      <Modal
        visible={visible}
        cancelButtonProps={{ style: { display: "none" } }}
        onCancel={() => setVisible(false)}
        onOk={() => setVisible(false)}
        style={{ minWidth: "80vw" }}
      >
        <Template contract={data[0]} user={profile} />
      </Modal>
    </>
  );
}
const ItemText = ({ changeFunc, label, value, editable = false }) => {
  const [ROLE] = React.useState(localStorage.getItem('role'))

  return (
    <span
      style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: 5 }}
    >
      <Typography.Text strong style={{ flex: 1, paddingRight: 10, fontSize: 16 }}>
        {label} :
      </Typography.Text>
      <Typography.Text
        style={{
          fontSize: 16,
          flex: 2,
        }}
        editable={
          editable && (ROLE == 'ROOT' || ROLE == 'ADMIN')
            ? {
                icon: <HighlightOutlined />,
                tooltip: 'Click để chỉnh sửa',
                onChange: e => changeFunc(e),
              }
            : false
        }
      >
        {value}
      </Typography.Text>
    </span>
  )
}
