/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import _store from "./redux/store";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory,
  useLocation,
} from "react-router-dom";
import { createBrowserHistory } from "history";
import { Provider, useSelector, useDispatch } from "react-redux";
import {
  Login,
  Layout,
  Users,
  UserDetail,
  Staff,
  Statistics,
  Request,
  CSKH,
} from "./containers";
import ReduxStore from "./redux/store";
import { Splash } from "./components";
import * as actions from "./redux/actions/auth";
import "./App.scss";
import api from "./api";
import { message } from "antd";
const _history = createBrowserHistory();

const AuthorizedRoutes = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { status } = useSelector((state) => state._auth);
  const [ROLE] = React.useState(localStorage.getItem("role"));
  const location = useLocation();
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    checkLock();
  }, []);

  React.useEffect(() => {
    checkLock();
  }, [location]);

  React.useEffect(() => {
    setLoading(true);
    const tokenFromStorage = localStorage.getItem("access_token");

    if (tokenFromStorage) dispatch(actions.initialLogin());
    if (!status) history.replace("/auth");
    else history.replace("/?page=1&search=&searchId=&meta=1,2,3");
  }, [status]);

  const checkLock = async () => {
    try {
      if (location.pathname == "/auth") return;
      const { data } = await api.get("/users/profile");
      if (!data) {
        dispatch(actions.Logout());
        return;
      }
      if (data.data.isLocked) {
        message.warning("Tài khoản của bạn đã bị khoá!");
        dispatch(actions.Logout());
      }
    } catch (error) {
      dispatch(actions.Logout());
    }
  }
  return (
    <>
      <>
        {status ? (
          <Layout>
            <Switch>
              <Route path="/" exact>
                <Users />
              </Route>
              <Route path="/detail">
                <UserDetail />
              </Route>
              <Route path="/cskh">
                <CSKH />
              </Route>
              <Route path="/staffs">
                <Staff />
              </Route>
              <Route path="/statistics">
                <Statistics />
              </Route>
              <Route path="/requests">
                <Request />
              </Route>
            </Switch>
          </Layout>
        ) : (
          <Switch>
            <Route exact path="/auth">
              <Login />
            </Route>
          </Switch>
        )}
      </>
    </>
  );
};

function App() {
  return (
    <Provider store={_store}>
      <Router history={_history}>
        <AuthorizedRoutes />
      </Router>
    </Provider>
  );
}

export default App;
