import React, { useEffect } from 'react'
import api from '../../api'
import { Table, Pagination, Typography } from 'antd'
import ReactTimeAgo from 'react-time-ago'
export default function Request() {
  const [page, setPage] = React.useState(1)
  const [metadata, setMetadata] = React.useState({})
  useEffect(() => {
    ;(async () => {
      const { data } = await api.get(`/requests/all?page=${page}`)
      setMetadata(data.data)
    })()
  }, [page])
  const columns = [
    {
      title: 'Khách hàng',
      key: 'phone',
      render: (text, record) => <Typography.Text>{record.userId?.phone}</Typography.Text>,
    },
    {
      title: 'CMND/CCCD',
      key: 'id',
      render: (text, record) => <Typography.Text>{record.userId?.kyc.id_number}</Typography.Text>,
    },
    {
      title: 'Tên',
      key: 'name',
      render: (text, record) => <Typography.Text>{record.userId?.kyc?.name}</Typography.Text>,
    },
    {
      title: 'Số tiền rút',
      key: 'amount',
      render: (text, record) => (
        <Typography.Text>{record.contractId?.amount?.toLocaleString()}</Typography.Text>
      ),
    },
    {
      title: 'Trạng thái',
      key: 'status',
      render: (text, record) => (
        <Typography.Text>
          {record.status == 'rejected'
            ? 'Thất bại'
            : record.status == 'pending'
            ? 'Chờ xác nhận'
            : 'Đã xác nhận'}
        </Typography.Text>
      ),
    },
    {
      title: 'Ghi chú',
      key: 'response',
      render: (text, record) => (
        <Typography.Text>
          {record.status == 'rejected'
            ? record.contractId?.response
            : record.status == 'pending'
            ? 'Đã gửi yêu cầu chờ admin xác nhận'
            : 'Đã thành công'}
        </Typography.Text>
      ),
    },
    {
      title: 'Yêu cầu lúc',
      key: 'created_at',
      render: (text, record) => (
        <Typography.Text>
          <ReactTimeAgo date={record.created_at} locale="vi-VN" timeStyle="twitter" />
        </Typography.Text>
      ),
    },
  ]
  return (
    <div>
      {' '}
      <>
        <Table columns={columns} dataSource={metadata.docs} pagination={false} />
        <div style={{ display: 'flex', justifyContent: 'flex-end', paddingTop: 10 }}>
          <Pagination
            total={metadata.totalDocs}
            current={page}
            onChange={e => setPage(e)}
            pageSize={10}
            pageSizeOptions={['10']}
          />
        </div>
      </>
    </div>
  )
}
