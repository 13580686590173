/* eslint-disable import/no-anonymous-default-export */
import React, { useEffect, useState } from "react";
import api from "../../api";
import {
  Table,
  Typography,
  Space,
  Modal,
  Button,
  Select,
  Input,
  message,
  Popconfirm,
} from "antd";
import moment from "moment";
import { HighlightOutlined, LockOutlined, UnlockOutlined } from "@ant-design/icons";
import { ChangePasswordUser } from "../../components";
export default () => {
  const [onReload, setOnReload] = useState(false);
  const [supporters, setSupporters] = useState([]);
  const [refetcher, setRefetcher] = useState(false);

  useEffect(() => {
    (async () => {
      const { data } = await api.get("/users/get-all-admins");
      console.log("data", data);
      setSupporters(data.data);
    })();
  }, [onReload, refetcher]);

  async function lockUser(data) {
    try {
      await api.put(`/users/lock-user`, {
        userId: data._id,
        locked: true,
      });
      setRefetcher((prev) => !prev);
      message.success("Khoá thành công.");
    } catch (err) {
      message.error("Có lỗi xảy ra, vui lòng thử lại sau.");
    }
  }
  async function unlockUser(data) {
    try {
      await api.put(`/users/lock-user`, {
        userId: data._id,
        locked: false,
      });
      setRefetcher((prev) => !prev);
      message.success("Mở khoá thành công.");
    } catch (err) {
      message.error("Có lỗi xảy ra, vui lòng thử lại sau.");
    }
  }

  const columns = [
    {
      title: "Tên nhân viên",
      dataIndex: "name",
      key: "name",
      render: (text, record) => (
        <Typography.Text>{record?.kyc?.name || "kjkjk"}</Typography.Text>
      ),
    },
    {
      title: "Tài khoản nhân viên",
      dataIndex: "phone",
      key: "phone",
    },
    // {
    //   title: "Trạng thái",
    //   dataIndex: "is_active",
    //   key: "is_active",
    //   render: (text, record) => (
    //     <Typography.Text>
    //       {record.is_active ? "Đang kích hoạt" : "Đã khoá"}
    //     </Typography.Text>
    //   ),
    // },

    {
      title: "Tuỳ chọn",
      key: "optional",
      render: (text, record) => (
        <Space>
          <Popconfirm
            title="Xoá ? Không thể khôi phục"
            onConfirm={() => deleteItem(record._id)}
          >
            <Button danger>Xoá</Button>
          </Popconfirm>
          {!record.isLocked ? (
            <Popconfirm
              title="Bạn có chắc chắn muốn khoá người dùng này?"
              onConfirm={() => lockUser(record)}
            >
              <Button icon={<LockOutlined />} danger shape="round">
                Khoá
              </Button>
            </Popconfirm>
          ) : (
            <Popconfirm
              title="Bạn có chắc chắn muốn mở khoá người dùng này?"
              onConfirm={() => unlockUser(record)}
            >
              <Button icon={<UnlockOutlined />} shape="round">
                Mở khoá
              </Button>
            </Popconfirm>
          )}
          <ChangePasswordUser userId={record._id} />
        </Space>
      ),
    },
  ];

  const [addSupporter, setAddSupporter] = useState(false);
  const [account, setAccount] = useState("");
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");

  async function create() {
    try {
      await api.post("auth/create", {
        phone: account,
        fullName: name,
        password,
      });
      setAccount("");
      setName("");
      setPassword("");
      message.success("Thêm thành công.");
      setAddSupporter(false);
      setTimeout(() => {
        setRefetcher((prev) => !prev);
      }, 500);
    } catch (err) {
      message.error("Có lỗi xảy ra. Vui lòng thử lại sau.");
    }
  }

  async function deleteItem(id) {
    try {
      await api.delete(`/users/${id}`);
      message.success("Xoá thành công.");
      setRefetcher((prev) => !prev);
    } catch (err) {
      message.error("Có lỗi xảy ra. Vui lòng thử lại sau.");
    }
  }
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {!addSupporter ? (
          <Button onClick={() => setAddSupporter(true)} type="primary">
            Thêm
          </Button>
        ) : (
          <>
            <Space>
              <Input
                placeholder="Nhập tên"
                type="primary"
                onChange={(e) => setName(e.target.value)}
              />
              <Input
                placeholder="Nhập tài khoản"
                type="primary"
                onChange={(e) => setAccount(e.target.value)}
              />
              <Input.Password
                placeholder="Nhập mật khẩu"
                type="primary"
                onChange={(e) => setPassword(e.target.value)}
                autoComplete="new-password"
              />
              <Button type="primary" onClick={create}>
                Thêm mới
              </Button>
              <Button onClick={() => setAddSupporter(false)}>Huỷ</Button>
            </Space>
          </>
        )}
      </div>
      <br />
      <Table dataSource={supporters} columns={columns} pagination={false} />
    </>
  );
};
